import Navbar from "./components/Navbar/Navbar.js";
import TextForm from "./components/TextForm/TextForm.js";
import About from "./components/About/About.js"
import Copied from "./components/Copied/Copied.js";


function App() {
  function themeChange() {
    let body = document.querySelector("body");
    let key = body.classList.contains("dark") ? "remove" : "add";
    body.classList[key]("dark");
  }
  return (

    <div>
      <Copied />
      <Navbar title="Text-Utils" onTheme={themeChange} />
      <TextForm heading="Enter the text below" placeholder="Your text goes here..." />
      <About />
    </div>

  );

}


export default App;

